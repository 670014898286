const Links = {
	LANDING: '/',
	DASHBOARD: '/home',
	ABOUT: '/about',
	PRIVACY_POLICY: '/privacy',

	EMAIL_SHOUTOUT_LOGIN: '/login',
	CAMARADLY_SHOUTOUT_LOGIN: '/shoutout-login',
	LOGIN: '/',
	Login: (from) => (!from ? Links.LOGIN : `${Links.LOGIN}?from=${from}`),

	User: (username) => `/${username}`,
	UserAchievements: (username, achievement = undefined) =>
		`/${username}/achievements${achievement ? `?t=${achievement}` : ''}`,
	Company: (company) => `/c/${company}`,
	Shoutout: (id) => `/?shoutout=${id}`,
	StrongLinkRequest: (userId, acceptingUser, accept) =>
		`/api/users/${userId}/strong-link?email=true&accept=${!!accept}&user=${acceptingUser}`,

	Private: {
		LOGOUT: '/auth/logout',
		VERIFY_USER: '/auth/verify-request',
	},
};

export const LinkUtils = {
	isCompanyPage: (pathname) => pathname.startsWith('/c/'),
	isShoutoutPage: (pathname) =>
		pathname === '/c/[name]' ||
		pathname === '/home' ||
		pathname === `/[username]`,
	isAchievementsPage: (pathname) => pathname === '/[username]/achievements',
	isOwnProfile: (user, router) =>
		router.pathname === '/home' || router.query?.username === user?.username,
};

export default Links;
