import ShoutoutGiven5Image from '../../../public/badges/shoutouts-given-5.svg';
import ShoutoutReceived10Image from '../../../public/badges/shoutouts-received-10.svg';

export const BadgeType = {
	SHOUTOUTS_GIVEN_1: 'shoutout_given_1',
	SHOUTOUTS_GIVEN_5: 'shoutout_given_5',
	SHOUTOUTS_GIVEN_10: 'shoutout_given_10',
	SHOUTOUTS_GIVEN_25: 'shoutout_given_25',
	SHOUTOUTS_GIVEN_50: 'shoutout_given_50',
	SHOUTOUTS_GIVEN_75: 'shoutout_given_75',

	SHOUTOUTS_RECEIVED_1: 'shoutout_received_1',
	SHOUTOUTS_RECEIVED_5: 'shoutout_received_5',
	SHOUTOUTS_RECEIVED_10: 'shoutout_received_10',
	SHOUTOUTS_RECEIVED_25: 'shoutout_received_25',
	SHOUTOUTS_RECEIVED_50: 'shoutout_received_50',
	SHOUTOUTS_RECEIVED_75: 'shoutout_received_75',
};

/** Shoutouts Given */
export const ShoutoutGivenBadge = {
	[BadgeType.SHOUTOUTS_GIVEN_1]: {
		description: 'Give 1 shoutout',
		image: () => <ShoutoutGiven5Image />,
		imageUrl: 'badges/shoutouts-given-5.png',
		count: 1,
	},
	[BadgeType.SHOUTOUTS_GIVEN_5]: {
		description: 'Give 5 shoutouts',
		image: () => <ShoutoutGiven5Image />,
		imageUrl: 'badges/shoutouts-given-5.png',
		count: 5,
	},
	[BadgeType.SHOUTOUTS_GIVEN_10]: {
		description: 'Give 10 shoutouts',
		count: 10,
	},
	[BadgeType.SHOUTOUTS_GIVEN_25]: {
		description: 'Give 25 shoutouts',
		count: 25,
	},
	[BadgeType.SHOUTOUTS_GIVEN_50]: {
		description: 'Give 50 shoutouts',
		count: 50,
	},
	[BadgeType.SHOUTOUTS_GIVEN_75]: {
		description: 'Give 75 shoutouts',
		count: 75,
	},
};

/** Shoutouts Received */
export const ShoutoutReceivedBadge = {
	[BadgeType.SHOUTOUTS_RECEIVED_1]: {
		description: 'Receive 1 shoutout',
		image: () => <ShoutoutReceived10Image />,
		imageUrl: 'badges/shoutouts-received-10.png',
		count: 1,
	},
	[BadgeType.SHOUTOUTS_RECEIVED_5]: {
		description: 'Receive 5 shoutouts',
		count: 5,
	},
	[BadgeType.SHOUTOUTS_RECEIVED_10]: {
		description: 'Receive 10 shoutouts',
		image: () => <ShoutoutReceived10Image />,
		imageUrl: 'badges/shoutouts-received-10.png',
		count: 10,
	},
	[BadgeType.SHOUTOUTS_RECEIVED_25]: {
		description: 'Receive 25 shoutouts',
		count: 25,
	},
	[BadgeType.SHOUTOUTS_RECEIVED_50]: {
		description: 'Receive 50 shoutouts',
		count: 50,
	},
	[BadgeType.SHOUTOUTS_RECEIVED_75]: {
		description: 'Receive 75 shoutouts',
		count: 75,
	},
};

const Badge = { ...ShoutoutGivenBadge, ...ShoutoutReceivedBadge };

export default Badge;
