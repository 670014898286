import React from 'react';

export default function PageSection({
	fullScreen,
	banner,
	className = '',
	...props
}) {
	const classes = 'page-container';
	const mClasses = `page-section ${className} ${!fullScreen ? classes : ''}`;
	const bannerHtml =
		fullScreen && banner ? (
			<div className={classes}>
				<div className="page-banner">
					<img className="banner-image" src={banner.image} alt={banner.alt} />
				</div>
				{props.children}
			</div>
		) : undefined;
	const child = fullScreen ? (
		<div className={classes}>{props.children}</div>
	) : (
		props.children
	);
	return props.div ? (
		<div className={mClasses}>{banner ? bannerHtml : child}</div>
	) : (
		<section className={mClasses}>{banner ? bannerHtml : child}</section>
	);
}
