import capitalize from 'lodash/capitalize';
import upperFirst from 'lodash/upperFirst';
import Links from '../navigation/Links';
import Badge from '../data/achievements/Badge';
import querystring from 'query-string';

const SHOUTOUT_REGEX = /@\[:(.*?):\]\(:(.*?):\)/g;

const Util = {
	capitalize: (str) => capitalize(str),
	titleCase: (str) => upperFirst(str),
	plural: (str, count) => (count > 1 ? `${str}s` : str),
	getRandomNumber: (n) => Math.floor(Math.random() * n),

	isColoredNavbar: (pathname) =>
		pathname === Links.LANDING ||
		pathname === Links.LOGIN ||
		pathname.startsWith(`${Links.LOGIN}?`),

	convertShoutoutTagsToText(str) {
		let response = str || '';
		if (str.includes('@[:')) {
			const matches = str.matchAll(SHOUTOUT_REGEX);

			if (matches)
				for (const match of matches) {
					response = response.replace(
						match[0],
						`<strong>@${match[1]}</strong>`
					);
				}
		}

		const emails = Util.extractEmails(str, false);
		for (const email of emails) {
			response = response.replace(
				new RegExp(email, 'g'),
				(email) => `<strong>${email}</strong>`
			);
		}

		return response;
	},

	extractEmails(message, parseTags = true) {
		const originalMessage = message;
		try {
			let emails = new Set(),
				index = message.indexOf('@'),
				spaceIndex = -1,
				email;
			message = message.substring(index, message.length);

			while (
				index !== -1 &&
				index < message.length &&
				(spaceIndex === -1 || spaceIndex <= message.length)
			) {
				spaceIndex = message.indexOf(' ');

				if (spaceIndex === -1) {
					email = message.substring(message.indexOf('@') + 1, message.length);
					if (
						Util.isValidEmail(email) &&
						!emails.has(email) &&
						!email.includes('[')
					) {
						emails.add(email);
					}
					break;
				}

				email = message.substring(message.indexOf('@') + 1, spaceIndex);
				if (
					Util.isValidEmail(email) &&
					!emails.has(email) &&
					!email.includes('[')
				) {
					emails.add(email);
				}

				message = message.substring(spaceIndex + 1, message.length);

				index = message.indexOf('@');
				message = message.substring(index, message.length);

				index = message.indexOf('@');
				spaceIndex = message.indexOf(' ');
			}

			if (parseTags && originalMessage.includes('@[:')) {
				const matches = originalMessage.matchAll(SHOUTOUT_REGEX);
				if (matches)
					for (const match of matches) {
						const email = match[2];
						if (Util.isValidEmail(email) && !emails.has(email)) {
							emails.add(email);
						}
					}
			}

			return Array.from(emails);
		} catch (e) {
			return [];
		}
	},

	copyToClipboard(text) {
		if (typeof navigator !== 'undefined') {
			navigator.clipboard?.writeText(text);
		}
	},

	isValidEmail: (email) => email && /\S+@\S+\.\S+/.test(email),

	updateQuery(obj) {
		const url = new URL(window.location);
		if (obj) {
			for (const key in obj) {
				if (obj.hasOwnProperty(key)) {
					if (obj[key]) url.searchParams.set(key, obj[key]);
					else url.searchParams.delete(key);
				}
			}
		}
		window.history.pushState({}, '', url);
		return window.location.pathname + window.location.search;
	},

	sortAchievements(achievements) {
		return achievements
			?.map((s) => (Badge[s] ? { id: s, ...Badge[s] } : null))
			.filter((s) => s?.image)
			.sort((a, b) => b.count - a.count);
	},

	mergeArrays(arr1, arr2, key) {
		if (!arr1 || !arr2) return arr1 || arr2;

		const mergeDeep = (target, ...sources) => {
			if (!sources.length) return target;
			const source = sources.shift();

			if (target instanceof Object && source instanceof Object) {
				for (const key in source) {
					if (source[key] instanceof Object) {
						if (!target[key]) Object.assign(target, { [key]: {} });
						mergeDeep(target[key], source[key]);
					} else {
						Object.assign(target, { [key]: source[key] });
					}
				}
			}

			return mergeDeep(target, ...sources);
		};

		const aKeyed = arr1.reduce((acc, cur) => ({ ...acc, [cur[key]]: cur }), {});
		const bKeyed = arr2.reduce((acc, cur) => ({ ...acc, [cur[key]]: cur }), {});
		const merged = mergeDeep(aKeyed, bKeyed);
		return Object.values(merged);
	},

	getQuery: (obj) =>
		obj ? querystring.stringify(obj, { skipEmptyString: true }) : '',
	withQuery: (link, query) =>
		`${link}${
			query && Object.keys(query).length > 0 ? `?${Util.getQuery(query)}` : ''
		}`,
};

export default Util;
